

/* ==========================================================================
   General items 基本設定
   ========================================================================== */

@font-face{
	font-family: 'NotoSansCJKtc-Thin-min';
  src: url('../fonts/NotoSansCJKtc-Thin-min.otf');
}




body{
  position: relative;
	margin: 0px;
	padding: 0px;
}

p{
	line-height: 1.5em;
  .paragraph;
	font-size: @font-size-p+5;
	color: #ddd;
  text-align: justify;
  @media screen and (min-width: 768px){
    line-height: 2em;
    font-size: @font-size-p;
  }
}

a{
  color: @gray-base-darker;
  text-decoration: none;
  &:hover{
    color: @gray-base;
    text-decoration: none;
  }
}

/* ==========================================================================
   single article style
   ========================================================================== */

.article{

  h3{font-size: 18px; color: @theme-base-lighter;}
  h4{font-size: 16px; color: @theme-base-lighter;}
  h5{font-size: 14px; color: @theme-base-lighter;}
  h6{font-size: 12px; color: @theme-base-lighter;}

  em{background-color: #fbff94; color: @theme-base-darker; padding: 2px; font-style: normal;}
  small{font-size: 10px;}
  code{font-family: monospace; display: inline-block; color: #757575; }
  blockquote{color:#757575; background: #f9f9f9; border-left: 10px solid #ccc; margin: 1.5em 10px; padding: 0.5em 10px; quotes: "\201C""\201D""\2018""\2019";}

}


/* ==========================================================================
   layout 版面
   ========================================================================== */

//**** header ****

#header{}

//**** content ****

#content{
  
}

//**** aside ****

#aside{}

//**** pre-footer ****

#pre-footer{
  clear: both;
}

//**** footer ****

#footer{

}


/* ==========================================================================
   Components 元件
   ========================================================================== */

//**** navbar ****

@media (max-width: 767px){
  .navbar-fixed-top {
    z-index: 100;
    position: absolute;
  }
  .navbar-inverse {
    border: none;
    background-color: transparent;
  }
}

.navbar{
  .navbar-theme;
  @media (min-width: @grid-float-breakpoint) {
    width: 100%;
    min-height: 50px;
    margin-bottom: @margin-base*0;
    border: 0px;
  }
}

.navbar-collapse {
  .border(0px,0px,0px,0px);
  padding: 0px, @padding-large-horizontal;
  background-color: #000;
  @media (min-width: @grid-float-breakpoint) {
    text-align: center;
    width: auto;
    border-top: 0;
    .boxShadow(none);
    background-color: transparent;
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px;
  .boxShadow(none);
  .borderRadius(4px);
}

.navbar-nav > li > a {
  padding: @padding-small-horizontal;
  line-height: 20px;
  .sub-title;
  font-size: 16px;
  color: @white-base;
  text-align: center;
}

@media (min-width: 769px) {
  .navbar-nav {
    float: left;
    display: inline-block;
    margin-left: @margin-base;
  }
  .navbar-nav > li {
    float: none;
    display: inline-block;
  }
  .navbar-nav > li > a {
    margin-right: 1em;
  }
}

.navbar-inverse .navbar-nav{
  > li {
    > a {
    text-transform: uppercase;
    color: @gray-base;
    &:hover,
    &:focus{
    background: transparent;
      }
    }
  &.active > a,
  &.active > a:hover,
  &.active > a:focus{
  background: transparent;
    }
  }
  i{
    margin-right: 5px;
  }   
}

.navbar-inverse .navbar-toggle {
  border:1px solid #fff;
  &:hover,
  &:focus{
   background-color: @theme-base; 
  }
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: @white-base;
}

.dropdown-menu{
  background-color: #222;
  border: none;
}


/* ==========================================================================
   module 模組 （_mod）
   ========================================================================== */


//**** logo module****

.mod_logo{
  position: relative;
  a{
  float: left;
  font-family: @font-family-title;
  padding-top: 10px;
  .boxSizing;
  }
  img{
  float: left;
  margin-right: @margin-base/2;
  .boxSizing; 
  }
}



//**** breadcrumb module ****

.mod_breadcrumb{
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: @margin-base*2;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;
  > li{
    display: inline-block;
    > a{
      font-family: @font-family-title;
      font-size: @font-size-h3;
    }
    + li:before {
      font-size: @font-size-h3;
      content: "@{breadcrumb-separator}\00a0"; 
      padding: 0 5px;
      color: @breadcrumb-color;
    }
  }
  > .active {
    color: @breadcrumb-active-color;
  }
}

//**** sidebar module ****

.mod_sidebar{
  .sidebar-group{
    border: 1px solid @gray-base;
    margin-bottom: @margin-base;
    .boxSizing;
    ul{
      padding: @padding-small-horizontal;
      list-style: square url('../img/list-style.png');
      margin-left: @margin-base;
      > li{
        margin-bottom: @margin-base/3;
        position: relative;
        > a{
          font-family: @font-family-title;
          font-size: @font-size-p+5;
          color: @white-base;
          padding-left: @padding-small-horizontal;
          letter-spacing: 3px;
          .boxSizing;
          &:hover{text-decoration: underline;}
        }
        .sidebar-group-sub{
          >li{
            margin-bottom: 0px;
            >a{
              color: @gray-base;
            }
          &.active > a,
          &.active > a:hover,
          &.active > a:focus{
            text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .sidebar-title{
    .sub-title;
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    color: @white-base;
    padding: @padding-small-horizontal;
    background-color: @theme-base;
  }
}

//**** group article module****

.mod_group-article{
  max-width: 960px;
  margin-top: 17%;
  margin-right: auto;
  margin-left: auto;

  img{
    margin: @margin-base auto;


  }
  h2{
    .sub-title;
    text-align: center;
    padding: 20px;      
  }
}

//**** single article module****

//**** attachment image module****

.mod_attachment-image{
  .col-md-3, .col-xs-6{
    margin-bottom: @margin-base/3;
  }
}

//**** blog post module ****

.mod_blog-post{
  .blog-post-title{
    .sub-title;
    padding-bottom: 10px;
    border-bottom: 3px dotted #9d9d9d;
  }
}

//**** album module ****

.mod_album{
  .album-wrapper{
  	overflow: auto;
  	width: 100%;
  	background-color: @gray-base;
  	padding: 0px;
  	.boxSizing;
    .album-image{
    padding: 0px;
    margin: 0px;
    }
  }
  .btn{
    width: 50%;
    margin-bottom: @margin-base/3;
    color: @white-base;
    background-color: @theme-base-lighter;
    &:hover{
      background-color: @theme-base;
    }
  }
}

@media (min-width: @grid-float-breakpoint){
  section:nth-child(even){
    .album-image{
      float: right;
    }
  }
}

//**** contact module ****

.mod_contact{
  .container-fluid{
    .boxSizing;
    margin: @margin-base*4 20px;
  }
  .contact-map{
    margin-top: @margin-base;
  }
  .contact-form h2{
    .sub-title;
    color: @gray-base;
    margin-top: 0px;
    margin-bottom: @margin-base/3;
  }
  .contact-name, .contact-mail, .contact-company{
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: 1px solid @white-base;
    margin-bottom: @margin-base/3;
    padding: @contact-form-padding;
    display: inline-block;
    font-family: @font-family-paragraph;
    .boxSizing;
  }
  .contact-message{
    width: 100%;
    background-color: transparent;
    border: 1px solid @white-base;
    padding: @contact-form-padding;
    display: inline-block;
    font-family: @font-family-paragraph;
    .boxSizing;
  }
  .contact-info{
    position: relative;
    bottom: 0px;
    width: 90%;
    height: auto;
    margin: 0 auto;
    margin-top: @margin-base;
    padding: 20px;
    .borderRadius(4px);
    text-align: center;
    h2{
      color: @gray-base;
      font-size: 20px;
      display: inline-block;
      margin-right: @margin-base/3;
    }
  }
}

//**** footer module ****

.mod_footer-group{
  width: 100%;
  overflow: auto;
  margin-top: 10px;
  .footer-left{
    float: left;
    p{
      color: @black-base;
      font-size: 10px;
      @media (min-width: @grid-float-breakpoint){
        font-size: 16px;
      }
    }
  }
  .footer-right{
    display: none;
    @media (min-width: 769px){
      display: block;
      float: right;
      .mod_social-icon{
        .facebook{
          .socialIcon;
        }
        .twitter{
          .socialIcon;
          background-position: -30px top;
        }
        .google-plus{
          .socialIcon;
          background-position: -60px top;
        }
      }  
    }
  }
}

//**** treeview module ****

.mod_side-nav{
  display: none;
  @media (min-width: @grid-float-breakpoint){
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 50;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: #26a65b;
    .side-nav{
      margin-right: -21px; /* 20px padding + 1px border */
      margin-bottom: 20px;
      margin-left: -20px;
      ul{
        list-style: none;
        li{         
          a{
            font-family: @font-family-paragraph;
            color:@white-base;
            padding:10px 15px;
            position: relative;
            &:hover,
            &:focus{
              color: #c8f7c6;
              background: transparent;
            }
          }
          &.active > a,
          &.active > a:hover,
          &.active > a:focus{
            color: #fff;
            background-color: #039372;
          }
          ul{
            padding-left: 0px;
            li{
              a{
                font-family: @font-family-paragraph;
                color:@white-base;
                display: block;
                padding: 10px 15px 10px 1.5em;
                text-decoration: none;
                &:hover,
                &:focus{
                  color: #c8f7c6;
                  background: transparent;
                }
              }
            }
          }
        }     
      }
    }
  }
}

.glyphicon{
  margin-right: 5px;
}

.collapse{
  &.in{background-color: #27834b;}
}
.collapsing{background-color: #27834b;}

.side-nav li.active:after, .navbar-nav li.active:before {
  top: 10px;
  right: 0%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.side-nav li.active:after {
  border-right-color: #e7ebec;
  border-width: 10px;
  margin-left: -6px;
  z-index: 1;
}
.side-nav li.active:before {
  border-width: 9px;
  margin-left: -9px;
  z-index: 1;
}



/* ==========================================================================
   Decorator 裝飾物 （跨全站_common）
   ========================================================================== */

.common_paper{
  position: relative;
  background-color: @gray-base-darker;
  padding: @padding-base-vertical*5;
  margin-bottom: @margin-base;
  .borderRadius(10px);
  .boxSizing;
  width: 100%;
  overflow: auto;
  > img{
  float: left;
  margin: @margin-base/3;
  }
}

.common_paper-pinhole{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: url('../img/body-bg.jpg');
  background-position: 0 50%;
  background-repeat: repeat-x;
  z-index: 2;
  float: right;
}


.common_title{
  position: relative;
  padding: @padding-base-vertical 0px;
  margin: @margin-base auto;
  margin-top: 80px;
  border-bottom: 3px solid #ddd;

  img{
    .boxSizing;
  }
  h1{
    .main-title;
    text-transform: uppercase;
    display: inline-block;
    padding-right: 30px;
    padding-left: 10px;
    .boxSizing
  }
}

.common_description{
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0px;
  .boxSizing;

  .container-fluid{
    .boxSizing; 
    margin: @margin-base*3 18px;
  } 

  h1{
    .main-title;
    text-align: center;
    margin-bottom: 20px;
    margin-top: @margin-base/3;
    padding: 20px 0px;
  }

  h2{
    .sub-title;
    margin-top: 20px;
    text-align: justify;
  }

}

.common_blockquote{
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.common_btn-more{
  .sub-title;
  text-transform: uppercase;
  float: right;
  color: @white-base;
  font-size: @font-size-p;
  background-color: @theme-base;
  padding: 10px;
  &:hover{
    background-color: @gray-base;
  }
}

.common_demo{
  margin-bottom: @margin-base;
  h2{
    display: inline-block;
    padding: 10px 0px;
    color: @gray-base-darker;
  }
}

.common_title-decor-h-1{
  padding: 20px 0px;
  border-bottom: 7px solid transparent;
  border-top: 0px;
  border-image: url('../img/title-color.png') 10 10 stretch;
}

.common_title-decor-h-2{
  padding: 20px 0px;
  border-bottom: 7px solid transparent;
  border-top: 0px;
  border-image: url('../img/title-color2.png') 10 10 stretch;
}

.common_title-decor-v-1{
  padding: 0px 20px;
  border-right: 7px solid transparent;
  border-left: 0px;
  border-image: url('../img/title-color3.png') 10 10 stretch;
}

.common_title-decor-v-2{
  padding: 0px 20px;
  border-left: 7px solid transparent;
  border-right: 0px;
  border-image: url('../img/title-color4.png') 10 10 stretch;
}



.common_scroll-down-button{  
  text-align: center;
  padding-top: 80vh;
  cursor: pointer;
  img{
    display: inline;
  }
}

.common_scroll-down-button-1, .common_scroll-down-button-2{
  text-align: center;
  cursor: pointer;
  img{
    display: inline;
  }
}

.common_scroll-up-button{
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  img{
    display: inline;
  }
  p{
    text-align: center;
    margin: 0px;
  }
}

// **** hexagon ****


.common_hexagon{
  float: left;
  margin-top: 100px;
  width: 300px;
  height:  400px;
  background-color: #fff;
  position: relative;
  margin-left: 1%;
  &:before{
    content: " ";
    width: 0; height: 0;
    border-bottom: 100px solid #fff;
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
    position: absolute;
    top: -100px;
  }
  &:after{
    content: "";
    width: 0;
    position: absolute;
    bottom: -100px;
    border-top: 100px solid #fff;
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
  }
}

.hexagon-row{
  clear: left;
}
.even{
    margin-left: 150px;
  }



// **** diamond ****

#diamond {
  position: relative;
  padding: 0;
  margin: @margin-base*4 auto;
  >div{
    background-color: #ffffff;
    position: absolute;
    transform-origin: center center;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
    &:hover{
      position: absolute;
      transform-origin: center center;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      >span{
        display: block;
        margin-right: auto;
        margin-left: auto;
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    }
    > span{
      display: block;
      text-align: center;
      width: 100px;
      .sub-title;
      margin-top: @margin-base;
      margin-right: auto;
      margin-left: auto;
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transition-duration: 0.8s;
      -moz-transition-duration: 0.8s;
      -o-transition-duration: 0.8s;
      transition-duration: 0.8s;
      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      -o-transition-property: -o-transform;
      transition-property: transform;
    }
  }
}

.veil{
  position: absolute;
  top:-40px;
  left: 2px;
}

/* ==========================================================================
   Utilities 工具 （跨全站_common）
   ========================================================================== */

.common_twenty{
  width: 100%;
  background: none;
  height: 20px;
  clear: both;
}

.common_clear{
  clear: both;
}


/* ==========================================================================
   Change from bootstrap 引自bootstrap並覆蓋的東西
   ========================================================================== */

//**** thumbnail ****

.thumbnail{
  margin-bottom: 0px;
}

.img-thumbnail{
  background: transparent;
  border: 0px;
  border-radius: 0px;
  @media (min-width: @grid-float-breakpoint){
    max-width: 500px;
  }
}


//**** button ****

.btn{
  &:hover,
  &:focus{
    color: @white-base;
  }
}

.btn-block{
  .sub-title;
  width: 50%;
  display: inline-block;
  text-transform: uppercase;
  color: @white-base;
  margin-top: @margin-base/2;
  background-color: @theme-base;
  &:hover{
    background-color: @yellow-base;
  } 
}

.btn-submit{
  
  display: inline-block;
  text-transform: uppercase;
  color: @black-base;
  margin-top: @margin-base/2;
  background-color: @orange-base;
  &:hover{
    color: @black-base;
    background-color: @yellow-base;
  } 
  @media (min-width: @grid-float-breakpoint){
    .sub-title;
  }
}

.btn-cancel{
  
  display: inline-block;
  text-transform: uppercase;
  color: @white-base;
  margin-top: @margin-base/2;
  background-color: @black-base;
  &:hover{
    color: @black-base;
    background-color: @gray-base;
  } 
  @media (min-width: @grid-float-breakpoint){
    .sub-title;
    color: @white-base;
  }
}

.btn-edit{
  background: transparent;
  color: rgba(3, 147, 114, 0.6);
  padding: 0px;
  &:hover,
  &:focus{
    color: rgba(3, 147, 114, 1.0);
  }
}

.btn-remove{
  background: transparent;
  color: rgba(238, 6, 0, 0.6);
  padding: 3px;
  &:hover,
  &:focus{
    color: rgba(238, 6, 0, 1.0);
  }
}

//**** list-group ****

.list-group{
  background-color: transparent;
  h2{
    .sub-title;
    margin: 0px;
  }
  p{
    margin: 0px;
    color: @gray-base-darker;
  }
}

.list-group-item{
  &.active,
  &.active:hover,
  &.active:focus{
    background-color: @theme-base;
    border-color: @theme-base;
  }
}

//**** pagination ****

.pagination{
  float: right;
  background: transparent;
  border-radius: 0px;
  margin: 30px 0;
  > li{
    
    > a{
      background-color: @theme-base-lighter;
      border-radius: 0px !important; 
      border: none;
      .main-title;
      font-size: 15px;
      font-family: 'OpenSans-Light', Helvetica, Tahoma, Arial, STXihei, "华文细黑", "Microsoft YaHei", "Microsoft JhengHei", sans-serif; 
      margin-right: 5px;
    &:hover{
      color: @white-base;
      background-color: @theme-base;
      
      }
    }
  }
}

//**** panel ****

.panel-default{
  border: 2px solid @gray-base;
  .panel-heading{
    background-color: transparent;
    &:hover{
      background-color: @theme-base;
    }
    .panel-title{
      > a{
        .sub-title;
        color: @theme-base-lighter;
      &:hover{
        color: @white-base;
        } 
      }
    }
  }
}

.panel{
    background: transparent;
  }

//**** table ****

.table{
  background-color: #fff;
  border: none;
  margin: @line-height-computed, auto;
  thead{
    background-color: transparent;
    >tr
      >th{
        .sub-title;
        border:none;
        color: #888888;
        margin: 0px;
        &:last-child{
          text-align: right;
        }
      }
  }
  tbody{
    >tr{
      height: 60px;
      >td{
        .paragraph;
        border:none;
        color: @gray-base-darker;
        vertical-align: middle;
        &.status{
          display: inline-block;
        }
      }
    }
  }
}



//**** carousel ****

.carousel-inner{
  max-height: 500px;
  >.item{
    >img{
      width: 100%;
    }
  }
}

.btn-group{
  display: none;
  @media screen and (min-width: 769px){
    display: inline-block;
    float: right;
    padding-top: 10px;
    border: 0px;
    background: transparent;
  }
}

.container-fluid{
  padding-left: @margin-base;
  padding-right: @margin-base;
}


.mod_form{
  padding: 30px;
  background-color: #f9f9f9;
  margin-bottom: 30px;
  .form-group{
    label{
      .sub-title;
      line-height: 1.5em;
    }
    input.form-control{
      color: #3e3e3e;
      font-size: 16px;
      height: 35px;
    } 
    textarea.form-control{
      color: #3e3e3e;
      font-size: 20px;
    } 
  }
}






















