//**** unisharp base color ****

@white-base-darker:		#f2f2f2; //for article
@gray-base:				#9d9d9d;
@gray-base-darker:		#757575; //for paragraph
@body-bg:				#e7ebec;
@theme-base:			#5c5c5c; 
@theme-base-lighter:	#77c59e; //for button
@theme-base-darker:		#3e3e3e; //for title

@white-base:			#fff;
@yellow-base:			#ffe100; 
@orange-base:			#fabe00;
@green-base-lighter:	#aacd03; 
@green-base-darker:		#6fb92c;
@black-base:			#3e3e3e; 

//**** heineken base color ****
@light-green-1:			#87b658;
@light-green-2: 		#649c3d;
@dark-green-1:			#4d7631;
@dark-green-2:			#275435;
@white-1:				#e7ebec;
@white-2:				#a6bcab;
@red:					#b95f59;
@orange:				#f49a05;




//**** unisharp base typography ****

@font-family-title: 	Helvetica, Tahoma, Arial, STXihei, "华文细黑", "Microsoft YaHei", "Microsoft JhengHei", sans-serif;
@font-family-paragraph: Helvetica, Tahoma, Arial, STXihei, "华文细黑", 'Microsoft YaHei', 'Microsoft JhengHei', sans-serif;


@font-size-h1:			30px; //main-title
@font-size-h2:			18px; //sub-title
@font-size-p:			16px; //paragraph


// **** lightcafe link ****

@link-color:			@gray-base;

// **** lightcafe padding, margin, line height ****

@margin-base:			30px; 

// **** general function ****


.main-title{
	font-family: @font-family-title;
	color: #1d5833;
	font-size: @font-size-h1;
	margin: 0px;
}

.sub-title{
	font-family: @font-family-title;
	color: @black-base;
	font-size: @font-size-h2;
	line-height: 2em;
	margin: 0px;
}

.paragraph{
	font-family: @font-family-paragraph;
	color: @gray-base-darker;
	font-size: @font-size-p;
}


.opacity (@opacity: 0.8) {
	-webkit-opacity: 	@opacity;
	-moz-opacity: 		@opacity;
	opacity: 		@opacity;
}


.boxShadow (@hor: 2px, @vert: 3px, @blur: 3px, @shadow: #888888) {
	-webkit-box-shadow: @hor @vert @blur @shadow;
	-moz-box-shadow: @hor @vert @blur @shadow;
	box-shadow: @hor @vert @blur @shadow;
}

.boxSizing (@type: border-box) {
	-webkit-box-sizing: @type;
	-moz-box-sizing:    @type;
	box-sizing:         @type;
}

.borderRadius (@radius: 0px) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;
}

.border(@topborder:3px, @rightborder:0px, @bottomborder:3px, @leftborder:0px){
	border-top: @topborder;
	border-right: @rightborder;
	border-bottom: @bottomborder;
	border-left: @leftborder;
	border-style: solid;
	border-color: @white-base;
}


.socialIcon{
	width: 30px;
	height: 30px;
	background-image: url('../img/social-icon.png');
	background-position: left top;
	background-repeat: no-repeat;
	display: inline-block;
	margin-right: @margin-base/3;
}



// **** theme function ****

.navbar-theme{
	.borderRadius;
  	background-color: #222;
}




// **** thumbnails ****

@thumbnail-border:            @white-base;

// **** panels ****

@panel-heading-padding:       10px 30px;

//**** breadcrumbs ****

@breadcrumb-padding-vertical:   8px;
@breadcrumb-padding-horizontal: 0px;
//** Breadcrumb background color
@breadcrumb-bg:                 transparent;
//** Breadcrumb text color
@breadcrumb-color:              @gray-base;
//** Text color of current page in the breadcrumb
@breadcrumb-active-color:       @gray-base-darker;
//** Textual separator for between breadcrumb elements
@breadcrumb-separator:          "/";

//**** table ****

@table-bg:                      @white-base-darker;
@table-border-color:            #ddd;


//**** pagination ****

@pagination-color:              @link-color;
@pagination-bg:                 @white-base-darker;
@pagination-border:             #ddd;

//**** contact form ****

@contact-form-padding:			8px 37px 9px 15px;

//**** carousel ****

@carousel-indicator-active-bg:                #37bc9b;



